.block-text {
  text-align: center;
  color: darkblue;
  width: 50%; /* This will make the main content take up half the page */
  margin-left: auto; /* This will center the main content */
  margin-right: auto; /* This will center the main content */
  text-align: justify; /* This will make the text align on both margins */
}

.normal-text {
  text-align: center;
  color: darkblue;
  width: 50%; /* This will make the main content take up half the page */
  margin-left: auto; /* This will center the main content */
  margin-right: auto; /* This will center the main content */
}

.contact-button {
  display: inline-block;
  background-color: #008CBA; /* Change as needed */
  color: white; /* Change as needed */
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 20px 2px 4px; /* Adjust the first value as needed */
  cursor: pointer;
  border: none;
  border-radius: 4px;
}