/* FILEPATH: /Users/jferas/feras-information-technologies/src/components/Header.css */
.header {
  text-align: center;
}

.header h1 {
  font-size: 3em; /* Adjust this value as needed */
}

.welcome-text {
  color: white;
}

.company-name {
  color: darkblue;
}