body {
  font-family: Arial, sans-serif;
  margin: 0;
  height: 100vh;
  background: linear-gradient(to bottom, skyblue, white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header, .footer {
  background-color: #b0d4f1; /* Darker pastel blue */
  padding: 1rem;
  text-align: center;
}

.rounded-button {
  background-color: #0077cc; /* Vibrant blue */
  border: 3px solid #005faa;
  border-radius: 25px; /* Rounded corners */
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s ease;

  &:hover {
      background-color: #005faa;
  }
}
